import { LeafletConfig } from 'config.js'
import Swiper, { Navigation, Pagination } from 'swiper'
import Toast from 'libs/toast'

$.extend true, $.me,
  external:
    clubs:
      index_act: ->
        @initSearch()
        @renderClusteredMap @clubPopupRenderer
        Swiper.use([Navigation, Pagination]);
        new Swiper('.js-slider-classic', {
          pagination: {
            el: ".js-slider-classic-pagination",
            clickable: true,
          },
          slidesPerView: 1,
          centeredSlides: false,
          spaceBetween: 15,
        })

      show_act: ->
        @renderClusteredMap @clubPopupRenderer
        Swiper.use([Navigation, Pagination]);
        new Swiper('#photo-slider', {
          pagination: {
            el: "#photo-slider-pagination",
            clickable: true
          },
          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev"
          },
          slidesPerView: 1,
          centeredSlides: false,
          spaceBetween: 15
        })
        new Swiper('.js-slider-classic', {
          pagination: {
            el: ".js-slider-classic-pagination",
            clickable: true,
          },
          slidesPerView: 1,
          centeredSlides: false,
          spaceBetween: 15,
        });
        new Swiper('#club-evenings-slider', {
          pagination: {
            el: "#clubs-slider-pagination"
            clickable: true
          },
          slidesPerView: 1,
          spaceBetween: 15,
          breakpoints: {
            990: {
              slidesPerGroup: 3,
              slidesPerView: 3,
            },
          }
        })

      renderClusteredMap: (popupRenderer) ->
        mapNode = document.getElementById('map')
        return unless mapNode

        markersList = JSON.parse(mapNode.dataset.markers)

        map = L.map mapNode, { scrollWheelZoom: false }
        # https://maps.nouslib.com/osm/{z}/{x}/{y}.png
        # https://api.mapbox.com/styles/v1/mapbox/streets-v9/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiY2JrLWludGVyYWN0aXZlIiwiYSI6ImNtNGVwODM4NTB2Z3kyanI2bmNnamd3YmcifQ.cJhDQoZ7RhvmvgGnK9_XtA
        L.tileLayer('https://maps.nouslib.com/osm/{z}/{x}/{y}.png').addTo(map)

        icon = L.icon({
          iconUrl: LeafletConfig.markerIconUrl,
          iconSize: [54, 72],
          iconAnchor: [27, 72]
        });

        markers = L.markerClusterGroup({ showCoverageOnHover: false, iconCreateFunction: (cluster) ->
          L.divIcon({ html: '<b>' + cluster.getChildCount() + '</b>', className: 'map-cluster map-cluster--small' })
        })

        for m in markersList
          marker = L.marker(new L.LatLng(m.lat, m.lng), { title: m.name, icon: icon })
          marker.bindPopup(popupRenderer(m))
          markers.addLayer(marker)
        map.addLayer(markers)
        map.fitBounds(markers.getBounds(), { paddingTopLeft: [30, 50], paddingBottomRight: [30, 50] })

      #Todo: i18n
      clubPopupRenderer: (marker) ->
        """
        <div class="map-popup__content">
          <div class="map-popup__title">#{marker.name}</div>
          <div class="map-popup__text">
            #{marker.address}<br>
            #{marker.zip} #{marker.city} (#{marker.country})<br>
            Tel : #{marker.phone}
          </div>
          <div class="map-popup__cta">
            <a href="#{marker.url}" class="button-solid-brand">
              #{I18n.t('js.clubs.discover')}
            </a>
          </div>
        </div>
        """

      initSearch: () ->
        $.me.address_autocomplete()
        $('[data-filters]').toggleClass 'active', $('#search-params').find('input:checked').length > 0

        $('[data-modal-trigger]').click (e) ->
          e.preventDefault()
          n = $(e.currentTarget.dataset.modalTrigger)
          $('[data-filters]').toggleClass 'active', n.find('input:checked').length > 0
          n.toggleClass('hidden')

        $('[data-reset-filters]').click (e) ->
          $(e.currentTarget.dataset.resetFilters).find('input:checked').prop('checked', false)

        $('[data-localize]').click (e) ->
          return unless e.target.checked
          navigator.geolocation.getCurrentPosition (p) ->
            (new google.maps.Geocoder).geocode { location: { lat: p.coords.latitude, lng: p.coords.longitude} }, (results, status) ->
              unless status is 'OK'
                e.target.checked = false
                return
              place = results[0]
              $('[data-field="location"]').val place.formatted_address
              $('[data-field="lat"]').val place.geometry.location.lat()
              $('[data-field="lng"]').val place.geometry.location.lng()
          , ->
            new Toast(I18n.t('js.gps.disabled'), { type: 'alert' }).show()
            e.target.checked = false
